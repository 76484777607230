import React, { useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Container, Card, Row, Col } from "reactstrap";
import SEO from "../components/SEO";
import TitleBar from "../components/Layout/TitleBar";
import BreadCrumbs from "../components/Layout/BreadCrumbs";
import pic1 from "../assets/images/pic1.jpg";
import pic2 from "../assets/images/pic2.jpg";
import pic3 from "../assets/images/services1.jpg";
import pic4 from "../assets/images/services2.jpg";
// import { Helmet } from "react-helmet";

const ServicesPage = () => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <Fragment>
     <SEO
        title="Services"
        description="Cannabis and Psychedelics Law LLP offers LP applications for commerical cannabis cultivation, cannabis retail storefront applications and operator licensing, medical cannabis regulatory advice corporate structure guidance, psilocybin exemptions and regulatory guidance, and business plans in the cannabis and psychedelics sector." 
      />

      <div className="wrapper mt-5">
        <div className="main bg-grey">
          <Container>
            <TitleBar title="Services" />
            <BreadCrumbs page="Services" />
            <Card className="pl-4 pr-4 pb-4">
              <Row className="mt-4">
                <Col md="7">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      size="lg"
                      className="mr-2 opacity-2"
                    />
                    <p className="mt-n1">
                      LP Applications for commercial cannabis cultivation and
                      processing facilities
                    </p>
                  </div>

                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      size="lg"
                      className="mr-2 opacity-2"
                    />
                    <p className="mt-n1">
                      Cannabis retail storefront applications and operator
                      licencing in Ontario, British Columbia, Alberta,
                      Saskatchewan, Manitoba, Newfoundland, Yukon, Northwest
                      Territories (NTLCC compliance advisory), and Nunavut.
                    </p>
                  </div>
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      size="lg"
                      className="mr-2 opacity-2"
                    />
                    <p className="mt-n1">
                      Medical Cannabis Regulatory Advice Corporate Structure
                      Guidance
                    </p>
                  </div>
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      size="lg"
                      className="mr-2 opacity-2"
                    />
                    <p className="mt-n1">
                      Psilocybin exemptions and regulatory guidance
                    </p>
                  </div>

                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      size="lg"
                      className="mr-2 opacity-2"
                    />
                    <p className="mt-n1">
                      Regulatory guidance in the emerging psychedelics sector
                    </p>
                  </div>
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      size="lg"
                      className="mr-2 opacity-2"
                    />
                    <p className="mt-n1">
                      Business plans in the cannabis and psychedelics sectors
                    </p>
                  </div>
                  <img src={pic2} className="content-image mb-3" alt="Greenhouse with marijuana plants" />
                  <img src={pic1} className="content-image mb-3" alt="Greenhouse with marijuana plants" />

                  <p className="font-weight-bold">
                    Contact us for a consultation today: 416-499-7945
                  </p>
                </Col>
                <Col md="5" className="text-center">
                  <img src={pic3} alt="Gloves" className="content-image mb-3" />
                  <img src={pic4} className="content-image mb-3" alt="Pouring solution into petrie dish" />
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default ServicesPage;
